/*!
 * Bootstrap v4.0.0-beta (https://getbootstrap.com)
 * Copyright 2011-2017 The Bootstrap Authors
 * Copyright 2011-2017 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@media print {
  *, :after, :before {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a, a:visited {
    text-decoration: underline;
  }

  thead {
    display: table-header-group;
  }

  img, tr {
    page-break-inside: avoid;
  }

  h2, h3, p {
    orphans: 3;
    widows: 3;
  }

  h2, h3 {
    page-break-after: avoid;
  }

  .navbar {
    display: none;
  }

  .table {
    border-collapse: collapse !important;
  }

  .table td, .table th {
    background-color: #fff !important;
  }
}

html {
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
  font-family: sans-serif;
  line-height: 1.15;
}

*, :after, :before {
  box-sizing: inherit;
}

@-ms-viewport {
  width: device-width;
}

article, footer, header, main, nav, section {
  display: block;
}

body {
  color: #212529;
  background-color: #fff;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h5 {
  margin-top: 0;
  margin-bottom: .5rem;
}

p, ol, ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ol ul, ul ol, ul ul {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

b {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

a {
  color: #007bff;
  -webkit-text-decoration-skip: objects;
  background-color: #0000;
  text-decoration: none;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([tabindex]), a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

code {
  font-family: monospace;
  font-size: 1em;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

[role="button"], a, area, button, input, label, select, textarea {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

table {
  border-collapse: collapse;
}

caption {
  color: #868e96;
  text-align: left;
  caption-side: bottom;
  padding-top: .75rem;
  padding-bottom: .75rem;
}

th {
  text-align: left;
}

label {
  margin-bottom: .5rem;
  display: inline-block;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

[type="reset"], [type="submit"], button, html [type="button"] {
  -webkit-appearance: button;
}

[type="button"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="reset"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="time"] {
  -webkit-appearance: listbox;
}

textarea {
  resize: vertical;
  overflow: auto;
}

fieldset {
  border: 0;
  min-width: 0;
  margin: 0;
  padding: 0;
}

legend {
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
  width: 100%;
  max-width: 100%;
  margin-bottom: .5rem;
  padding: 0;
  display: block;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button {
  height: auto;
}

[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

.h1, .h2, .h3, .h5, h1, h2, h3, h5 {
  color: inherit;
  margin-bottom: .5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
}

.h1, h1 {
  font-size: 2.5rem;
}

.h2, h2 {
  font-size: 2rem;
}

.h3, h3 {
  font-size: 1.75rem;
}

.h5, h5 {
  font-size: 1.25rem;
}

hr {
  border: 0;
  border-top: 1px solid #0000001a;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.small, small {
  font-size: 80%;
  font-weight: 400;
}

.mark, mark {
  background-color: #fcf8e3;
  padding: .2em;
}

code {
  color: #bd4147;
  background-color: #f8f9fa;
  border-radius: .25rem;
  padding: .2rem .4rem;
  font-family: Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 90%;
}

a > code {
  color: inherit;
  background-color: inherit;
  padding: 0;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.row {
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
}

.col, .col-10, .col-12, .col-lg-4, .col-lg-8, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-sm, .col-sm-10 {
  width: 100%;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
}

.col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.col-10 {
  flex: 0 0 83.3333%;
  max-width: 83.3333%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-sm-10 {
    flex: 0 0 83.3333%;
    max-width: 83.3333%;
  }
}

@media (min-width: 768px) {
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }

  .col-md-5 {
    flex: 0 0 41.6667%;
    max-width: 41.6667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.3333%;
    max-width: 58.3333%;
  }
}

@media (min-width: 992px) {
  .col-lg-4 {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }

  .col-lg-8 {
    flex: 0 0 66.6667%;
    max-width: 66.6667%;
  }
}

.table {
  background-color: #0000;
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
}

.table td, .table th {
  vertical-align: top;
  border-top: 1px solid #e9ecef;
  padding: .75rem;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #e9ecef;
}

.table tbody + tbody {
  border-top: 2px solid #e9ecef;
}

.table .table {
  background-color: #fff;
}

.btn {
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid #0000;
  border-radius: .25rem;
  padding: .5rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  transition: all .15s ease-in-out;
  display: inline-block;
}

.btn:focus, .btn:hover {
  text-decoration: none;
}

.btn.focus, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 3px #007bff40;
}

.btn.disabled, .btn:disabled {
  opacity: .65;
}

.btn.active, .btn:active {
  background-image: none;
}

a.btn.disabled, fieldset[disabled] a.btn {
  pointer-events: none;
}

.fade {
  opacity: 0;
  transition: opacity .15s linear;
}

.fade.show {
  opacity: 1;
}

.collapse {
  display: none;
}

.collapse.show {
  display: block;
}

tr.collapse.show {
  display: table-row;
}

tbody.collapse.show {
  display: table-row-group;
}

.collapsing {
  height: 0;
  transition: height .35s;
  position: relative;
  overflow: hidden;
}

.dropdown, .dropup {
  position: relative;
}

.dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-left: .3em solid #0000;
  border-right: .3em solid #0000;
  width: 0;
  height: 0;
  margin-left: .255em;
  display: inline-block;
}

.dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropup .dropdown-menu {
  margin-top: 0;
  margin-bottom: .125rem;
}

.dropup .dropdown-toggle:after {
  border-top: 0;
  border-bottom: .3em solid;
}

.dropdown-menu {
  z-index: 1000;
  float: left;
  color: #212529;
  text-align: left;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #00000026;
  border-radius: .25rem;
  min-width: 10rem;
  margin: .125rem 0 0;
  padding: .5rem 0;
  font-size: 1rem;
  list-style: none;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
}

.dropdown-item {
  clear: both;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background: none;
  border: 0;
  width: 100%;
  padding: .25rem 1.5rem;
  font-weight: 400;
  display: block;
}

.dropdown-item:focus, .dropdown-item:hover {
  color: #16181b;
  background-color: #f8f9fa;
  text-decoration: none;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  background-color: #007bff;
  text-decoration: none;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #868e96;
  background-color: #0000;
}

.show > a {
  outline: 0;
}

.dropdown-menu.show {
  display: block;
}

[data-toggle="buttons"] > .btn input[type="checkbox"], [data-toggle="buttons"] > .btn input[type="radio"] {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}

.nav {
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.nav-link {
  padding: .5rem 1rem;
  display: block;
}

.nav-link:focus, .nav-link:hover {
  text-decoration: none;
}

.nav-link.disabled {
  color: #868e96;
}

.navbar {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: .5rem 1rem;
  display: flex;
  position: relative;
}

.navbar > .container {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.navbar-nav {
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.navbar-nav .nav-link {
  padding-left: 0;
  padding-right: 0;
}

.navbar-nav .dropdown-menu {
  float: none;
  position: static;
}

.navbar-toggler {
  background: none;
  border: 1px solid #0000;
  border-radius: .25rem;
  padding: .25rem .75rem;
  font-size: 1.25rem;
  line-height: 1;
}

.navbar-toggler:focus, .navbar-toggler:hover {
  text-decoration: none;
}

.alert {
  border: 1px solid #0000;
  border-radius: .25rem;
  margin-bottom: 1rem;
  padding: .75rem 1.25rem;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }

  to {
    background-position: 0 0;
  }
}

.progress {
  text-align: center;
  background-color: #e9ecef;
  border-radius: .25rem;
  font-size: .75rem;
  line-height: 1rem;
  display: flex;
  overflow: hidden;
}

.media {
  align-items: flex-start;
  display: flex;
}

.list-group {
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
}

.list-group-item {
  background-color: #fff;
  border: 1px solid #00000020;
  margin-bottom: -1px;
  padding: .75rem 1.25rem;
  display: block;
  position: relative;
}

.list-group-item:first-child {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.list-group-item:last-child {
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem;
  margin-bottom: 0;
}

.list-group-item:focus, .list-group-item:hover {
  text-decoration: none;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #868e96;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.close {
  float: right;
  color: #000;
  text-shadow: 0 1px #fff;
  opacity: .5;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
}

.close:focus, .close:hover {
  color: #000;
  opacity: .75;
  text-decoration: none;
}

button.close {
  -webkit-appearance: none;
  background: none;
  border: 0;
  padding: 0;
}

.modal-open {
  overflow: hidden;
}

.modal {
  z-index: 1050;
  outline: 0;
  display: none;
  position: fixed;
  inset: 0;
  overflow: hidden;
}

.modal.fade .modal-dialog {
  transition: transform .3s ease-out, -webkit-transform .3s ease-out;
  transform: translate(0, -25%);
}

.modal.show .modal-dialog {
  transform: translate(0);
}

.modal-open .modal {
  overflow: hidden auto;
}

.modal-dialog {
  width: auto;
  margin: 10px;
  position: relative;
}

.modal-backdrop {
  z-index: 1040;
  background-color: #000;
  position: fixed;
  inset: 0;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: .5;
}

.modal-scrollbar-measure {
  width: 50px;
  height: 50px;
  position: absolute;
  top: -9999px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 30px auto;
  }
}

.tooltip {
  z-index: 1070;
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  word-wrap: break-word;
  opacity: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
  font-size: .875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-decoration: none;
  display: block;
  position: absolute;
}

.tooltip.show {
  opacity: .9;
}

.tooltip .arrow {
  width: 5px;
  height: 5px;
  display: block;
  position: absolute;
}

.tooltip .arrow:before {
  border-style: solid;
  border-color: #0000;
  position: absolute;
}

.tooltip-inner {
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: .25rem;
  max-width: 200px;
  padding: 3px 8px;
}

.popover {
  z-index: 1060;
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #0003;
  border-radius: .3rem;
  max-width: 276px;
  padding: 1px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
  font-size: .875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-decoration: none;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.popover .arrow {
  width: 10px;
  height: 5px;
  display: block;
  position: absolute;
}

.popover .arrow:after, .popover .arrow:before {
  border-style: solid;
  border-color: #0000;
  display: block;
  position: absolute;
}

.popover .arrow:before, .popover .arrow:after {
  content: "";
  border-width: 11px;
}

.popover-header {
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px);
  margin-bottom: 0;
  padding: 8px 14px;
  font-size: 1rem;
}

.popover-header:empty {
  display: none;
}

.popover-body {
  color: #212529;
  padding: 9px 14px;
}

.carousel {
  position: relative;
}

.carousel-item {
  backface-visibility: hidden;
  perspective: 1000px;
  align-items: center;
  width: 100%;
  transition: transform .6s, -webkit-transform .6s;
  display: none;
  position: relative;
}

.carousel-item-next, .carousel-item-prev, .carousel-item.active {
  display: block;
}

.carousel-item-next, .carousel-item-prev {
  position: absolute;
  top: 0;
}

.carousel-item-next.carousel-item-left, .carousel-item-prev.carousel-item-right {
  transform: translateX(0);
}

@supports ((-webkit-transform-style: preserve-3d)) or (transform-style: preserve-3d) {
  .carousel-item-next.carousel-item-left, .carousel-item-prev.carousel-item-right {
    transform: translate3d(0, 0, 0);
  }
}

.active.carousel-item-right, .carousel-item-next {
  transform: translateX(100%);
}

@supports ((-webkit-transform-style: preserve-3d)) or (transform-style: preserve-3d) {
  .active.carousel-item-right, .carousel-item-next {
    transform: translate3d(100%, 0, 0);
  }
}

.active.carousel-item-left, .carousel-item-prev {
  transform: translateX(-100%);
}

@supports ((-webkit-transform-style: preserve-3d)) or (transform-style: preserve-3d) {
  .active.carousel-item-left, .carousel-item-prev {
    transform: translate3d(-100%, 0, 0);
  }
}

.carousel-indicators {
  z-index: 15;
  justify-content: center;
  margin-left: 15%;
  margin-right: 15%;
  padding-left: 0;
  list-style: none;
  display: flex;
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
}

.carousel-indicators li {
  text-indent: -999px;
  background-color: #ffffff80;
  flex: 0 auto;
  width: 30px;
  height: 3px;
  margin-left: 3px;
  margin-right: 3px;
  position: relative;
}

.carousel-indicators li:before {
  content: "";
  width: 100%;
  height: 10px;
  display: inline-block;
  position: absolute;
  top: -10px;
  left: 0;
}

.carousel-indicators li:after {
  content: "";
  width: 100%;
  height: 10px;
  display: inline-block;
  position: absolute;
  bottom: -10px;
  left: 0;
}

.carousel-indicators .active {
  background-color: #fff;
}

.border {
  border: 1px solid #e9ecef !important;
}

.clearfix:after {
  clear: both;
  content: "";
  display: block;
}

.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-block {
    display: block !important;
  }
}

.fixed-top {
  z-index: 1030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.fixed-bottom {
  z-index: 1030;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

@supports (position: -webkit-sticky) or (position: sticky) {
  .sticky-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  border: 0;
  width: 1px;
  height: 1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.m-1 {
  margin: .25rem !important;
}

.visible {
  visibility: visible !important;
}

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  font: inherit;
  cursor: pointer;
  text-transform: none;
  color: inherit;
  background-color: #0000;
  border: 0;
  margin: 0;
  padding: 15px;
  transition-property: opacity, filter;
  transition-duration: .15s;
  transition-timing-function: linear;
  display: inline-block;
  overflow: visible;
}

.hamburger:hover {
  opacity: .7;
}

.hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  margin-top: -2px;
  display: block;
  top: 50%;
}

.hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
  background-color: #000;
  border-radius: 4px;
  width: 40px;
  height: 4px;
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: ease;
  position: absolute;
}

.hamburger-inner:after, .hamburger-inner:before {
  content: "";
  display: block;
}

.hamburger-inner:before {
  top: -10px;
}

.hamburger-inner:after {
  bottom: -10px;
}

.hamburger--squeeze .hamburger-inner {
  transition-duration: .1s;
  transition-timing-function: cubic-bezier(.55, .055, .675, .19);
}

.hamburger--squeeze .hamburger-inner:before {
  transition: top .1s .14s, opacity .1s;
}

.hamburger--squeeze .hamburger-inner:after {
  transition: bottom .1s .14s, transform .1s cubic-bezier(.55, .055, .675, .19);
}

.hamburger--squeeze.is-active .hamburger-inner {
  transition-delay: .14s;
  transition-timing-function: cubic-bezier(.215, .61, .355, 1);
  transform: rotate(45deg);
}

.hamburger--squeeze.is-active .hamburger-inner:before {
  opacity: 0;
  transition: top .1s, opacity .1s .14s;
  top: 0;
}

.hamburger--squeeze.is-active .hamburger-inner:after {
  transition: bottom .1s, transform .1s cubic-bezier(.215, .61, .355, 1) .14s;
  bottom: 0;
  transform: rotate(-90deg);
}

.p-t-0 {
  padding-top: 0;
}

.p-t-8 {
  padding-top: 8px;
}

.p-t-10 {
  padding-top: 10px;
}

.p-t-19 {
  padding-top: 19px;
}

.p-t-20 {
  padding-top: 20px;
}

.p-t-45 {
  padding-top: 45px;
}

.p-b-1 {
  padding-bottom: 1px;
}

.p-b-5 {
  padding-bottom: 5px;
}

.p-b-8 {
  padding-bottom: 8px;
}

.p-b-10 {
  padding-bottom: 10px;
}

.p-b-19 {
  padding-bottom: 19px;
}

.p-b-30 {
  padding-bottom: 30px;
}

.p-b-43 {
  padding-bottom: 43px;
}

.p-b-60 {
  padding-bottom: 60px;
}

.p-l-10 {
  padding-left: 10px;
}

.p-l-15 {
  padding-left: 15px;
}

.p-l-20 {
  padding-left: 20px;
}

.p-l-45 {
  padding-left: 45px;
}

.p-r-15 {
  padding-right: 15px;
}

.p-r-45 {
  padding-right: 45px;
}

.m-l-r-auto {
  margin-left: auto;
  margin-right: auto;
}

.sr-only {
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  white-space: nowrap !important;
  border: 0 !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.t-center {
  text-align: center;
}

.dis-block {
  display: block;
}

.bgwhite {
  background-color: #fff;
}

.hov-img-zoom {
  display: block;
  overflow: hidden;
}

.hov-img-zoom img {
  -o-transition: all .6s;
  width: 100%;
  height: auto;
  transition: all .6s;
}

.hov-img-zoom:hover img {
  transform: scale(1.1);
}

.hov-video-zoom {
  display: block;
  overflow: hidden;
}

.hov-video-zoom .hov-video-zoom-video {
  -o-transition: all .6s;
  width: 100%;
  transition: all .6s;
}

.hov-video-zoom:hover .hov-video-zoom-video {
  transform: scale(1.1);
}

.s-text5 {
  font-family: var(--regular);
  color: #999;
  font-size: 15px;
  line-height: 1;
}

.s-text6 {
  font-family: var(--regular);
  color: #999;
  font-size: 16px;
  line-height: 1.8;
}

.s-text8, .s-text8 a {
  font-family: var(--regular);
  color: #000;
  padding: 5px;
  font-size: 13px;
  line-height: 1.8;
}

.m-text2 {
  font-family: var(--regular);
  color: #333;
  text-transform: uppercase;
  font-size: 16px;
}

.m-text41 {
  font-family: var(--medium);
  color: #e65540;
  text-transform: uppercase;
  font-size: 30px;
  line-height: 1.2;
}

.m-text5 {
  font-family: var(--bold);
  color: #222;
  text-transform: uppercase;
  font-size: 30px;
  font-weight: bold;
  line-height: 1.2;
}

.m-text11 {
  font-family: var(--medium);
  color: #333;
  font-size: 16px;
  line-height: 1.5;
}

.bg6 {
  background-color: #f0f0f0;
}

.bg0-hov:hover {
  background-color: #e65540;
}

@media (max-width: 992px) {
  footer {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.lazy-load-map {
  width: 100%;
  height: 450px;
  position: relative;
  overflow: hidden;
}

.lazy-load-map iframe {
  border: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@font-face {
  font-display: optional;
  font-family: Montserrat Regular;
  src: url("../fonts/Montserrat-Regular.e8b8e881.woff2") format("woff2"), url("../fonts/Montserrat-Regular.e31ea2d1.woff") format("woff");
}

:root {
  --regular: "Montserrat Regular", system-ui, sans-serif;
  --medium: "Montserrat Regular", system-ui, sans-serif;
  --bold: "Montserrat Regular", system-ui, sans-serif;
  --black: "Montserrat Regular", system-ui, sans-serif;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body, html {
  font-family: var(--regular);
  height: 100%;
}

.a-reset {
  transition: none;
}

a {
  font-family: var(--regular);
  color: #666;
  -o-transition: all .4s;
  margin: 0;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.7;
  transition: all .4s;
}

a:focus {
  outline: none !important;
}

a:hover {
  color: #e65540;
  text-decoration: none;
}

h1, h2, h3, h5 {
  margin: 0;
}

p {
  font-family: var(--regular);
  color: #888;
  margin: 0;
  font-size: 15px;
  line-height: 1.7;
}

ul, li {
  margin: 0;
  list-style-type: none;
}

input {
  outline: none;
  border: none !important;
}

textarea {
  outline: none;
}

input:focus::-webkit-input-placeholder {
  color: #0000;
}

input:focus:-moz-placeholder {
  color: #0000;
}

input:focus::-moz-placeholder {
  color: #0000;
}

input:focus:-ms-input-placeholder {
  color: #0000;
}

textarea:focus::-webkit-input-placeholder {
  color: #0000;
}

textarea:focus:-moz-placeholder {
  color: #0000;
}

textarea:focus::-moz-placeholder {
  color: #0000;
}

textarea:focus:-ms-input-placeholder {
  color: #0000;
}

button {
  background: none;
  border: none;
  outline: none !important;
}

button:hover {
  cursor: pointer;
}

iframe {
  border: none !important;
}

.container {
  max-width: 1200px;
}

.animsition-loading-1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

[data-loader="ball-scale"] {
  background-color: #e65540;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  animation: .75s linear infinite ball-scale;
}

@keyframes ball-scale {
  0% {
    opacity: 1;
    transform: scale(.1);
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
}

.btn-back-to-top {
  opacity: .5;
  z-index: 1000;
  -o-transition: all .4s;
  background-color: #000;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  transition: all .4s;
  display: none;
  position: fixed;
  bottom: 40px;
  right: 40px;
}

.symbol-btn-back-to-top {
  line-height: 0;
}

.symbol-btn-back-to-top svg {
  color: #fff;
  stroke-width: 2.5px;
  width: 22px;
  height: 22px;
}

.btn-back-to-top:hover {
  opacity: 1;
  cursor: pointer;
}

@media (max-width: 576px) {
  .btn-back-to-top {
    bottom: 15px;
    right: 15px;
  }
}

.header1 {
  -o-transition: all .3s;
  height: 125px;
  transition: all .3s;
}

.fixed-header {
  height: 110px;
}

.container-menu-header {
  z-index: 1100;
  -o-box-shadow: 0 1px 5px 0px #0003;
  -ms-box-shadow: 0 1px 5px 0px #0003;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0 1px 5px #0003;
}

.topbar {
  background-color: #f5f5f5;
  justify-content: center;
  align-items: center;
  height: 45px;
  display: flex;
  position: relative;
}

.topbar-very-small-mobile {
  background-color: #f5f5f5;
  justify-content: center;
  align-items: center;
  height: 45px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: none;
  position: relative;
}

.topbar-social {
  align-items: center;
  height: 100%;
  padding-left: 40px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.topbar-email {
  font-family: var(--regular);
  color: #000;
  font-size: 13px;
  line-height: 1.7;
}

.topbar-child2 {
  flex-wrap: wrap;
  align-items: center;
  height: 100%;
  padding-right: 38px;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

.wrap_header {
  -o-transition: all .3s;
  background-color: #fff;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  transition: all .3s;
  display: flex;
  position: relative;
}

@media (max-width: 1320px) {
  .wrap_header {
    justify-content: right;
    padding-right: 15px;
  }
}

.fixed-header .wrap_header {
  height: 65px;
}

.logo {
  display: block;
  position: absolute;
  top: 50%;
  left: 52px;
  transform: translateY(-50%);
}

.main_menu {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0;
  list-style-type: none;
  display: flex;
}

.main_menu > li {
  padding: 20px 12px;
  display: block;
  position: relative;
}

.main_menu > li > a {
  font-family: var(--regular);
  color: #333;
  border-bottom: 1px solid #0000;
  padding: 0;
  font-size: 15px;
}

.main_menu > li:hover > a {
  border-bottom: 1px solid #333;
  text-decoration: none;
}

.main_menu li {
  position: relative;
}

.show-header-dropdown {
  transform: scale(1);
}

.wrap_header_mobile {
  background-color: #fff;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  min-height: 80px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  display: none;
}

.logo-mobile {
  display: block;
}

.btn-show-menu {
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
}

.hamburger {
  margin-top: 5px;
  transform: scale(.8);
}

.wrap-side-menu {
  background-color: #fff;
  border-top: 1px solid #ececec;
  width: 100%;
  display: none;
}

.side-menu {
  width: 100%;
}

.side-menu li {
  list-style-type: none;
}

.side-menu .main-menu {
  margin-bottom: 0;
}

.item-menu-mobile {
  background-color: #e65540;
}

.side-menu .main-menu > li > a {
  font-family: var(--regular);
  color: #fff;
  padding-left: 20px;
  font-size: 15px;
  line-height: 2.86;
}

.side-menu .main-menu > li {
  color: #fff;
  position: relative;
}

.side-menu .main-menu .arrow-main-menu {
  padding: 10px;
  font-size: 14px;
  position: absolute;
  top: 5px;
  right: 20px;
  -o-transition: all .4s !important;
  transition: all .4s !important;
}

.side-menu .main-menu .arrow-main-menu:hover {
  cursor: pointer;
}

.turn-arrow {
  transform: rotate(90deg);
}

.side-menu .sub-menu a {
  font-family: var(--regular);
  color: #333;
  padding-left: 20px;
  font-size: 13px;
  line-height: 2.5;
}

.side-menu .sub-menu > li {
  padding-left: 12px;
  padding-top: ;
}

.side-menu .sub-menu a:hover {
  padding-left: 20px;
  text-decoration: none;
  color: #e65540 !important;
}

.side-menu .sub-menu {
  background-color: #fff;
  display: none;
}

@media (min-width: 1166px) {
  .wrap-side-menu {
    display: none;
  }
}

.item-topbar-mobile {
  border-bottom: 1px solid #ececec;
}

.topbar-child2-mobile {
  flex-wrap: wrap;
  align-items: center;
  display: flex;
}

.fixed-header2 {
  z-index: 1300;
  visibility: hidden;
  -o-box-shadow: 0 1px 5px 0px #0003;
  -ms-box-shadow: 0 1px 5px 0px #0003;
  height: 65px;
  position: fixed;
  top: -70px;
  left: 0;
  box-shadow: 0 1px 5px #0003;
}

.show-fixed-header2 {
  visibility: visible;
  top: 0;
}

@media (max-width: 475px) {
  .show-meter {
    display: none;
  }

  .show-m {
    display: inline-block;
  }
}

@media (min-width: 476px) {
  .show-meter {
    display: inline-block;
  }

  .show-m {
    display: none;
  }
}

@media (max-width: 425px) {
  .price-129-medium-mobile-screens {
    display: none;
  }

  .topbar-very-small-mobile {
    display: flex;
  }
}

@media (max-width: 1166px) {
  .fixed-header .wrap_header_mobile {
    z-index: 9999;
    width: 100%;
    position: fixed;
    top: 0;
  }

  .fixed-header .wrap-side-menu {
    z-index: 9999;
    width: 100%;
    position: fixed;
    top: 80px;
  }

  .wrap_header_mobile {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex !important;
  }

  .wrap_header, .container-menu-header {
    display: none;
  }

  header {
    height: auto !important;
  }
}

.block2-btn-towishlist, .block2-btn-addwishlist {
  color: #fff;
  font-size: 20px;
  line-height: 0;
  display: block;
  position: absolute;
  top: 26px;
  right: 20px;
  transform: scale(0);
}

.block2-btn-addwishlist:hover {
  color: #fff;
}

.block2-btn-addwishlist:hover .icon_heart_alt {
  display: none;
}

.block2-btn-addwishlist:hover .icon_heart {
  display: block;
}

.block2-btn-towishlist .icon_heart_alt {
  display: none;
}

.block2-btn-towishlist .icon_heart {
  color: #e65540;
  display: block;
}

body {
  padding-right: 0 !important;
}

.modal {
  z-index: 1360;
  overflow-x: hidden;
  padding: 0 !important;
  overflow-y: auto !important;
}

.modal-open {
  overflow-y: scroll;
}

.modal-backdrop {
  background-color: #0000;
}

#modal-video-01 {
  z-index: 1350;
  background-color: #000c;
}

#modal-video-01 .modal-dialog {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  position: relative;
}

.video-mo-01 {
  opacity: 0;
  -o-transition: all 2s;
  width: 100%;
  height: 100%;
  transition: all 2s;
  position: absolute;
  top: 0;
  left: 0;
}

.video-mo-01 iframe {
  width: 100%;
  height: 100%;
}

.col {
  border: 1px solid red;
}

.table-ladder {
  border-collapse: collapse;
  border-spacing: 2px;
  border-spacing: 15px;
  border: 1px solid #0000;
  padding: 5px;
  line-height: 1.5em;
}

.table-tr-ladder {
  border-collapse: collapse;
  border-spacing: 2px;
  border-spacing: 15px;
  border: 1px solid green;
  padding: 5px;
  line-height: 1.5em;
}

.table-td-ladder {
  border-collapse: collapse;
  border-spacing: 2px;
  border-spacing: 15px;
  border: 1px solid gray;
  padding: 5px;
  line-height: 1.5em;
}

.table-td-ladder a {
  align-items: center;
  gap: 5px;
  display: flex;
}

/*!
 * Generated with CSS Flag Sprite generator (https://www.flag-sprites.com/)
 */
.flag {
  background: url("../images/flags.9f25fec5.webp") no-repeat;
  width: 16px;
  height: 11px;
  display: inline-block;
}

.flag.flag-de {
  background-position: -48px -11px;
}

.flag.flag-dk {
  background-position: -64px -11px;
}

.flag.flag-ee {
  background-position: -80px -11px;
}

.flag.flag-fi {
  background-position: -112px -11px;
}

.flag.flag-gb {
  background-position: -32px -22px;
}

.flag.flag-lv {
  background-position: 0 -44px;
}

.flag.flag-no {
  background-position: -112px -44px;
}

.flag.flag-pl {
  background-position: 0 -55px;
}

.flag.flag-se {
  background-position: -80px -55px;
}

.blueimp-gallery, .blueimp-gallery > .slides > .slide > .slide-content {
  -moz-backface-visibility: hidden;
  position: absolute;
  inset: 0;
}

.blueimp-gallery > .slides > .slide > .slide-content {
  opacity: 1;
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  margin: auto;
}

.blueimp-gallery {
  z-index: 999999;
  opacity: 0;
  direction: ltr;
  -ms-touch-action: none;
  touch-action: none;
  background: #000000e6;
  display: none;
  position: fixed;
  overflow: hidden;
}

.blueimp-gallery-display {
  opacity: 1;
  display: block;
}

.blueimp-gallery > .slides {
  height: 100%;
  position: relative;
  overflow: hidden;
}

.blueimp-gallery > .slides > .slide {
  float: left;
  text-align: center;
  -o-transition-timing-function: cubic-bezier(.645, .045, .355, 1);
  height: 100%;
  transition-timing-function: cubic-bezier(.645, .045, .355, 1);
  position: relative;
}

.blueimp-gallery, .blueimp-gallery > .slides > .slide > .slide-content {
  -o-transition: opacity .2s linear;
  transition: opacity .2s linear;
}

.blueimp-gallery > .slides > .slide-loading {
  background: url("../images/loading.7b99cf9b.webp") center / 64px 64px no-repeat;
}

.blueimp-gallery > .slides > .slide-loading > .slide-content {
  opacity: 0;
}

.blueimp-gallery > .slides > .slide-error {
  background: url("../images/error.28448445.webp") center no-repeat;
}

.blueimp-gallery > .slides > .slide-error > .slide-content {
  display: none;
}

.blueimp-gallery > .prev, .blueimp-gallery > .next {
  color: #fff;
  text-shadow: 0 0 2px #000;
  text-align: center;
  box-sizing: content-box;
  opacity: .9;
  cursor: pointer;
  background: #000000d9;
  border: 3px solid #fff;
  border-radius: 23px;
  width: 40px;
  height: 40px;
  margin-top: -23px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 60px;
  font-weight: 100;
  line-height: 30px;
  text-decoration: none;
  display: none;
  position: absolute;
  top: 50%;
  left: 15px;
}

.blueimp-gallery > .next {
  left: auto;
  right: 15px;
}

.blueimp-gallery > .close, .blueimp-gallery > .title {
  color: #fff;
  text-shadow: 0 0 2px #000;
  opacity: .8;
  margin: 0 40px 0 0;
  font-size: 20px;
  line-height: 30px;
  display: none;
  position: absolute;
  top: 15px;
  left: 15px;
}

.blueimp-gallery > .close {
  cursor: pointer;
  margin: -15px;
  padding: 15px;
  font-size: 30px;
  text-decoration: none;
  left: auto;
  right: 15px;
}

.blueimp-gallery > .play-pause {
  cursor: pointer;
  opacity: .5;
  background: url("../images/play-pause.f89f3007.png") no-repeat;
  width: 15px;
  height: 15px;
  display: none;
  position: absolute;
  bottom: 15px;
  right: 15px;
}

.blueimp-gallery-playing > .play-pause {
  background-position: -15px 0;
}

.blueimp-gallery > .prev:hover, .blueimp-gallery > .next:hover, .blueimp-gallery > .close:hover, .blueimp-gallery > .title:hover, .blueimp-gallery > .play-pause:hover {
  color: #fff;
  opacity: 1;
}

.blueimp-gallery-controls > .prev, .blueimp-gallery-controls > .next, .blueimp-gallery-controls > .close, .blueimp-gallery-controls > .title, .blueimp-gallery-controls > .play-pause {
  display: block;
  transform: translateZ(0);
}

.blueimp-gallery-single > .prev, .blueimp-gallery-left > .prev, .blueimp-gallery-single > .next, .blueimp-gallery-right > .next, .blueimp-gallery-single > .play-pause {
  display: none;
}

.blueimp-gallery > .slides > .slide > .slide-content, .blueimp-gallery > .prev, .blueimp-gallery > .next, .blueimp-gallery > .close, .blueimp-gallery > .play-pause {
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
}

body:last-child .blueimp-gallery > .slides > .slide-error {
  background-image: url("../images/error.8ee861ca.svg");
}

body:last-child .blueimp-gallery > .play-pause {
  background-image: url("../images/play-pause.6e80479e.svg");
  background-size: 40px 20px;
  width: 20px;
  height: 20px;
}

body:last-child .blueimp-gallery-playing > .play-pause {
  background-position: -20px 0;
}

* + html .blueimp-gallery > .slides > .slide {
  min-height: 300px;
}

* + html .blueimp-gallery > .slides > .slide > .slide-content {
  position: relative;
}



/*# sourceMappingURL=index-de.2425cc71.css.map */
